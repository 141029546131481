import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Header siteTitle="Impressum" subTitle="Angaben gemäß § 5 TMG:" />
    <div className="section">
      <div className="container">
        <div className="content">
          <div className="columns">
            <div className="column">
              <h3>Adresse</h3>
              <p>
                Arbeitskreis gegen Spielsucht e.V.
                <br />
                Südring 31
                <br />
                59423 Unna
              </p>
              <h3>Vertreten durch</h3>
              <p>Jürgen Trümper</p>
              <h3>Kontakt</h3>
              <p>
                02303 89669
                <br />
                info@ak-spielsucht.de
              </p>
            </div>
            <div class="column">
              <h3>Registereintrag</h3>
              <p>
                Eintragung im Vereinsregister.
                <br />
                Registergericht:Amtsgericht
                <br />
                Unna Registernummer: 6 VR 725
              </p>
              <h3>Steuernummer</h3>
              <p> 316/59290651</p>
              <h3>Quellenangaben für die verwendeten Bilder und Grafiken:</h3>
              <p>www.istockphoto.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
